import OrderIntegrationStatus from '../'
import OrderIntegrationStatusForm from '@/view/pages/OrderIntegrationStatus/popup/form'
import DeleteOrderIntegrationStatus from '@/view/pages/OrderIntegrationStatus/popup/remove'

export default [
  {
    name: 'OrderIntegrationStatus',
    path: '/orderIntegrationStatus',
    component: OrderIntegrationStatus,
    meta: {
      main_menu: true,
      slug: 'orderIntegrationStatus',
      category: 'Governance',
      page: 'orderIntegrationStatus',
      permissionKey: 'OrderIntegrationStatusMenu'
    },
    children: [
      {
        path: '/orderIntegrationStatus/form/:status/:id',
        name: 'OrderIntegrationStatusForm',
        component: OrderIntegrationStatusForm
      },
      {
        path: '/orderIntegrationStatus/:status/:id',
        name: 'DeleteOrderIntegrationStatus',
        component: DeleteOrderIntegrationStatus
      }
    ]
  }
]
