var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Popup",
    [
      _c(
        "template",
        { slot: "title" },
        [
          _vm.isEdit
            ? [
                _vm._v(
                  _vm._s(_vm.$t("OrderIntegrationStatus.popupForm_Title_Edit"))
                ),
              ]
            : [
                _vm._v(
                  _vm._s(
                    _vm.$t("OrderIntegrationStatus.popupForm_Title_Create")
                  )
                ),
              ],
        ],
        2
      ),
      _c("template", { slot: "content" }, [
        _c(
          "form",
          {
            staticClass: "Form",
            attrs: {
              id: "form-order-integration-status-group",
              "data-vv-scope": "orderForm",
            },
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.onSubmitForm.apply(null, arguments)
              },
            },
          },
          [
            _c(
              "div",
              { staticClass: "Form-item required" },
              [
                _c("label", { staticClass: "Form-item-label" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t("OrderIntegrationStatus.popupForm_Field_Name")
                    )
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "control" },
                  [
                    _c("customTextInput", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: _vm.nameValidationRules,
                          expression: "nameValidationRules",
                        },
                      ],
                      ref: "firstField",
                      attrs: {
                        id: "input-order-integration-status-groups-popup-field-name",
                        name: "name",
                        "data-vv-as": _vm.$t(
                          "OrderIntegrationStatus.popupForm_Field_Name"
                        ),
                        error: _vm.veeErrors.has("orderForm.name"),
                        disabled: _vm.form.isDefault && _vm.isEdit,
                        isDelayInput: true,
                      },
                      model: {
                        value: _vm.form.name,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "name", $$v)
                        },
                        expression: "form.name",
                      },
                    }),
                    _c(
                      "Button",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.isPendingName,
                            expression: "isPendingName",
                          },
                        ],
                        attrs: {
                          variant: "icon loading",
                          id: "btn-order-integration-status-groups-popup-loading-name",
                          tabindex: "-1",
                        },
                      },
                      [_c("Loading", { attrs: { theme: "disable" } })],
                      1
                    ),
                    _c("Button", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isValidateName,
                          expression: "isValidateName",
                        },
                      ],
                      attrs: {
                        variant: "icon check",
                        id: "btn-order-integration-status-groups-popup-ok-name",
                        tabindex: "-1",
                        iconName: "icon-check",
                        iconClass: "icon-check",
                        justıcon: true,
                      },
                    }),
                  ],
                  1
                ),
                _c("showValidateError", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.veeErrors.has("orderForm.name"),
                      expression: "veeErrors.has('orderForm.name')",
                    },
                  ],
                  attrs: { errorName: _vm.veeErrors.first("orderForm.name") },
                }),
              ],
              1
            ),
            !_vm.isHasClosure && !_vm.isEdit
              ? _c(
                  "div",
                  { staticClass: "Form-item" },
                  [
                    _c("CustomCheckbox", {
                      staticClass: "justify-flex-start",
                      attrs: {
                        id: "checkbox-isClosureStatus",
                        label: _vm.$t(
                          "OrderIntegrationStatus.popupForm_Field_isClosureStatus"
                        ),
                      },
                      model: {
                        value: _vm.form.isClosureStatus,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "isClosureStatus", $$v)
                        },
                        expression: "form.isClosureStatus",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "div",
              { staticClass: "Form-item" },
              [
                _c("CustomCheckbox", {
                  staticClass: "justify-flex-start",
                  attrs: {
                    id: "checkbox-isMailSend",
                    label: _vm.$t(
                      "OrderIntegrationStatus.popup_field_isSendMail"
                    ),
                  },
                  model: {
                    value: _vm.form.sendEmail,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "sendEmail", $$v)
                    },
                    expression: "form.sendEmail",
                  },
                }),
              ],
              1
            ),
            _vm.form.sendEmail
              ? _c("div", { staticClass: "Form-item required" }, [
                  _c("label", { staticClass: "Form-item-label" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t("OrderIntegrationStatus.popupForm_field_mail")
                      )
                    ),
                  ]),
                  _c("div", { staticClass: "control" }, [
                    _c("div", { staticClass: "input-listing" }, [
                      _c(
                        "div",
                        { staticClass: "input-listing-add" },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.emailInput,
                                expression: "emailInput",
                              },
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: _vm.validateMailRule,
                                expression: "validateMailRule",
                              },
                            ],
                            ref: "inputList",
                            staticClass: "txt",
                            class: {
                              "is-danger": _vm.veeErrors.has("orderForm.email"),
                            },
                            attrs: {
                              autocomplete: "off",
                              name: "email",
                              type: "text",
                              id: "input-listing",
                              "data-vv-as": _vm.$t(
                                "OrderIntegrationStatus.popupForm_field_mail"
                              ),
                            },
                            domProps: { value: _vm.emailInput },
                            on: {
                              keydown: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  ) &&
                                  _vm._k(
                                    $event.keyCode,
                                    "submit",
                                    undefined,
                                    $event.key,
                                    undefined
                                  )
                                )
                                  return null
                                $event.preventDefault()
                                return _vm.addItem.apply(null, arguments)
                              },
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.emailInput = $event.target.value
                              },
                            },
                          }),
                          _c("Button", {
                            attrs: {
                              size: "small",
                              variant: "icon global",
                              id: "btn-input-listing-add",
                              iconName: "icon-global-add",
                              iconClass: "icon-global-add",
                            },
                            on: { click: _vm.addItem },
                          }),
                          _c(
                            "p",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.veeErrors.has("orderForm.email") ||
                                    (!_vm.form.emailAddress &&
                                      _vm.form.sendEmail),
                                  expression:
                                    "veeErrors.has('orderForm.email') || (!form.emailAddress && form.sendEmail)",
                                },
                              ],
                              staticClass: "Form-item-help is-danger",
                              attrs: { id: "input-listing-add-error" },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.veeErrors.first("orderForm.email"))
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm.emailList.length > 0
                        ? _c(
                            "div",
                            { staticClass: "input-listing-items" },
                            _vm._l(_vm.emailList, function (item, index) {
                              return _c(
                                "div",
                                { staticClass: "item-list" },
                                [
                                  _c("span", { staticClass: "item" }, [
                                    _vm._v(_vm._s(item)),
                                  ]),
                                  _c("Button", {
                                    attrs: {
                                      variant: "icon close",
                                      id: `input-listing-remove-${index}`,
                                      iconName:
                                        "icon-popup-close-withCurrentColor",
                                      iconClass:
                                        "icon-popup-close-withCurrentColor",
                                      justIcon: true,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.removeItem(item)
                                      },
                                    },
                                  }),
                                ],
                                1
                              )
                            }),
                            0
                          )
                        : _vm._e(),
                    ]),
                  ]),
                ])
              : _vm._e(),
          ]
        ),
      ]),
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "Button",
            {
              attrs: {
                size: "medium",
                primary: "",
                variant: "full",
                type: "submit",
                form: "form-order-integration-status-group",
                disabled: _vm.isLoading,
              },
            },
            [
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.isLoading,
                      expression: "!isLoading",
                    },
                  ],
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.isEdit
                        ? _vm.$t("OrderIntegrationStatus.popupForm_Button_Edit")
                        : _vm.$t(
                            "OrderIntegrationStatus.popupForm_Button_Create"
                          )
                    )
                  ),
                ]
              ),
              _c("Loading", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isLoading,
                    expression: "isLoading",
                  },
                ],
                attrs: { theme: "disable" },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }