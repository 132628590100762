<template lang="pug">
Popup

    template(
      slot="title"
    )

      template(
        v-if="isEdit"
      ) {{ $t('OrderIntegrationStatus.popupForm_Title_Edit') }}

      template(
        v-else
      ) {{ $t('OrderIntegrationStatus.popupForm_Title_Create') }}

    template(
      slot="content"
    )

      form.Form(
        id="form-order-integration-status-group"
        data-vv-scope="orderForm"
        @submit.prevent="onSubmitForm"
      )

        .Form-item.required

          label.Form-item-label {{ $t('OrderIntegrationStatus.popupForm_Field_Name') }}

          .control

            customTextInput(
              id="input-order-integration-status-groups-popup-field-name"
              ref="firstField"
              v-model="form.name"
              v-validate="nameValidationRules"
              name="name"
              :data-vv-as="$t('OrderIntegrationStatus.popupForm_Field_Name')"
              :error="veeErrors.has('orderForm.name')"
              :disabled="form.isDefault && isEdit"
              :isDelayInput = "true"
            )

            Button(
              variant="icon loading",
              id="btn-order-integration-status-groups-popup-loading-name",
              tabindex="-1",
              v-show="isPendingName"
            )
              Loading(theme="disable")

            Button(
              variant="icon check",
              id="btn-order-integration-status-groups-popup-ok-name",
              tabindex="-1",
              v-show="isValidateName"
              iconName="icon-check"
              iconClass="icon-check"
              :justıcon="true"
            )
          showValidateError(
              v-show="veeErrors.has('orderForm.name')"
              :errorName="veeErrors.first('orderForm.name')"
            )

        .Form-item(v-if="!isHasClosure && !isEdit")
          CustomCheckbox.justify-flex-start(
            id="checkbox-isClosureStatus"
            :label="$t('OrderIntegrationStatus.popupForm_Field_isClosureStatus')"
            v-model="form.isClosureStatus")

        .Form-item
          CustomCheckbox.justify-flex-start(
            id="checkbox-isMailSend"
            :label="$t('OrderIntegrationStatus.popup_field_isSendMail')"
            v-model="form.sendEmail")
        .Form-item.required(v-if="form.sendEmail")
          label.Form-item-label {{ $t('OrderIntegrationStatus.popupForm_field_mail') }}
          .control
            .input-listing
              .input-listing-add
                input.txt(
                  ref="inputList"
                  autocomplete="off"
                  name="email"
                  type="text"
                  id="input-listing"
                  v-model="emailInput"
                  v-validate="validateMailRule"
                  @keydown.enter.submit.prevent="addItem"
                  :data-vv-as="$t('OrderIntegrationStatus.popupForm_field_mail')",
                  :class="{ 'is-danger': veeErrors.has('orderForm.email') }",
                )
                Button(
                  size="small"
                  variant="icon global",
                  id="btn-input-listing-add",
                  iconName="icon-global-add"
                  iconClass="icon-global-add"
                  @click ="addItem"
                )
                p.Form-item-help.is-danger(
                  v-show="veeErrors.has('orderForm.email') || (!form.emailAddress && form.sendEmail)"
                  id="input-listing-add-error"
                ) {{ veeErrors.first('orderForm.email') }}

              .input-listing-items(v-if="emailList.length > 0")
                .item-list(v-for="(item, index) in emailList")
                  span.item {{ item }}
                  Button(
                    variant="icon close",
                    :id="`input-listing-remove-${index}`",
                    iconName="icon-popup-close-withCurrentColor"
                    iconClass="icon-popup-close-withCurrentColor"
                    :justIcon="true"
                    @click ="removeItem(item)"
                  )

    template(slot="footer")
        Button(
          size="medium"
          primary,
          variant= "full"
          type="submit"
          form="form-order-integration-status-group"
          :disabled="isLoading"
        )
          span(v-show="!isLoading") {{ isEdit ? $t('OrderIntegrationStatus.popupForm_Button_Edit') : $t('OrderIntegrationStatus.popupForm_Button_Create') }}
          Loading(theme="disable", v-show="isLoading")

</template>
<script>
import { mapFields } from 'vee-validate'
import { mapActions, mapGetters } from 'vuex'
import { vueWaitLoader, omit } from '@/utils/baseOperations'

export default {
  name: 'OrderIntegrationStatusForm',

  data () {
    return {
      emailInput: null,
      emailList: [],
      form: {
        name: null,
        isClosureStatus: false,
        description: null,
        emailAddress: null,
        sendEmail: false
      }
    }
  },

  async mounted () {
    if (this.isEdit) await this.init()
    this.formFocus()
  },

  computed: {
    ...mapFields({
      flagsName: 'name'
    }),

    ...mapGetters('OrderIntegrationStatus', [
      'isHasClosure'
    ]),

    nameValidationRules () {
      const editUrl = `OrderIntegrationStatus/check/name?Id=${this.$route.params.id},Name`
      const newUrl = 'OrderIntegrationStatus/check/name,Name'
      return `required|max:64|very_singularity:${this.isEdit ? editUrl : newUrl}`
    },

    validateMailRule () {
      return this.emailList.length > 0 ? 'email|max:128' : 'required|email|max:128'
    },

    isLoading () {
      return this.$wait.is(['updateOrderIntegrationStatus', 'createOrderIntegrationStatus', 'onSubmitFormOrderIntegration'])
    },

    isEdit () {
      return this.$route.params.status === 'edit'
    },

    isPendingName () {
      return this.flagsName.pending
    },

    isValidateName () {
      if (this.flagsName.required) {
        return this.flagsName.valid && !this.isPendingName
      } else {
        return this.flagsName.valid && this.form.name !== '' && this.form.name !== null && !this.isPendingName
      }
    }
  },

  methods: {
    ...mapActions('Notify', [
      'notifyShow'
    ]),

    ...mapActions('OrderIntegrationStatus', [
      'getOrderIntegrationStatus',
      'createOrderIntegrationStatus',
      'updateOrderIntegrationStatus',
      'checkOrderIntegrationStatusName'
    ]),

    async init () {
      this.getOrderIntegrationStatus({id: this.$route.params.id})
        .then(res => {
          if (res) this.form = res.data.orderIntegrationStatus
          if (this.form.emailAddress) this.emailList = this.form.emailAddress.split(';')
        })
    },

    close () {
      this.$router.push({
        name: this.$route.matched[0].name
      })
    },

    formFocus () {
      this.$refs.firstField.focusCustomInput()
    },

    onSubmitForm: vueWaitLoader(async function () {
      await this.$validator.validateAll('orderForm').then(async result => {
        // form error
        if (!result || (!this.form.emailAddress && this.form.sendEmail)) return
        if (!this.form.sendEmail) this.form.emailAddress = null
        if (this.isEdit) {
          this.form = omit(this.form, ['isDefault'])
          return this.submitEdit()
        }
        return this.submitNew()
      })
    }, 'onSubmitFormOrderIntegration'),

    async submitNew () {
      await this.createOrderIntegrationStatus(this.form)
        .then(res => {
          if (res) {
            const message = this.$t('Global.notification_Created', { name: this.form.name })
            this.notifyShow({ message })
            this.$emit('getList')
            this.close()
          }
        })
    },

    async submitEdit () {
      await this.updateOrderIntegrationStatus(this.form)
        .then(res => {
          if (res) {
            const message = this.$t('Global.notification_Updated', { name: this.form.name })
            this.notifyShow({ message })
            this.$emit('getList')
            this.close()
          }
        })
    },

    addItem () {
      this.$validator.validate('orderForm.email').then(result => {
        if (this.emailInput) {
          if (!result) return
          this.emailList.push(this.emailInput)
          this.emailInput = ''
          this.onChangeListing()
        }
      })
    },

    removeItem (item) {
      this.emailList.splice(this.emailList.findIndex((element) => element === item), 1)
      this.onChangeListing()
    },

    onChangeListing () {
      this.form.emailAddress = this.emailList.join(';')
    }
  }
}
</script>

<style scoped lang="scss">

  .input-listing {
    &-add {
      .global {
        color: $color-success;
        z-index: $z-index-md;
        position: absolute;
        top: 5px;
        right: 0;
      }
    }

    &-items {
      display: flex;
      flex-wrap: wrap;
      margin-left: -10px;
      .item-list {
        display: flex;
        align-items: center;
        background-color: $color-white;
        border: solid 1px $color-gray;
        border-radius: $border-radius;
        margin-top: 10px;
        margin-left: 10px;
        padding-left: 10px;
        .item {
          color: $color-sidebar-dark;
          font-size: $font-size-normal;
        }
        .close {
          color: $color-red;
        }
      }
    }
  }
</style>
