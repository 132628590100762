import requestHandler from '@/utils/requestHandler'
import HTTP from '@/utils/http'
import { getPaginationNumber, PageGetters } from '@/utils/pagination'

export default {
  namespaced: true,
  state: {
    orderIntegrationStatusList: [],
    isHasClosure: false,
    Page: {
      number: 1,
      size: 20,
      total: 0
    }
  },
  getters: {
    Page: state => PageGetters({ state, stateName: 'Page' }),
    OrderIntegrationStatusList: state => state.orderIntegrationStatusList,
    isHasClosure: state => state.isHasClosure
  },
  actions: {
    async getOrderIntegrationStatusList({ commit, state }, params) {
      let payload
      let PageNumber
      if (params) {
        PageNumber = getPaginationNumber({ selectedPage: params.pageNumber, currentPage: state.Page.number })
        payload = {
          pageNumber: PageNumber,
          pageSize: params.pageSize
        }
      } else {
        payload = {}
      }
      const results = await requestHandler({
        title: 'getOrderIntegrationStatusList',
        methodName: 'getOrderIntegrationStatusList',
        method: () => {
          return HTTP.get('OrderIntegrationStatus/all', {
            params: { ...payload }
          })
        },
        success: result => {
          commit('SET_LIST', result.data)
          commit('SET_USED_CLOSURE_STATUS_ITEM', result.data.orderIntegrationStatusList)
          return result
        }
      })
      return results
    },
    async getOrderIntegrationStatus({ commit }, params) {
      const results = await requestHandler({
        title: 'getOrderIntegrationStatus',
        methodName: 'getOrderIntegrationStatus',
        method: () => {
          return HTTP.get('OrderIntegrationStatus', { params })
        },
        success: result => {
          return result
        }
      })
      return results
    },
    async createOrderIntegrationStatus({ commit }, params) {
      const results = await requestHandler({
        title: 'createOrderIntegrationStatus',
        methodName: 'createOrderIntegrationStatus',
        method: () => {
          return HTTP.post('OrderIntegrationStatus', params)
        },
        success: result => {
          return result
        }
      })
      return results
    },
    async deleteOrderIntegrationStatus({ commit }, params) {
      const results = await requestHandler({
        title: 'deleteOrderIntegrationStatus',
        methodName: 'deleteOrderIntegrationStatus',
        method: () => {
          return HTTP.delete('OrderIntegrationStatus', { params })
        },
        success: result => {
          return result
        }
      })
      return results
    },
    async updateOrderIntegrationStatus({ commit }, params) {
      const results = await requestHandler({
        title: 'updateOrderIntegrationStatus',
        methodName: 'updateOrderIntegrationStatus',
        method: () => {
          return HTTP.put('OrderIntegrationStatus', params)
        },
        success: result => {
          return result
        }
      })
      return results
    },
    async checkOrderIntegrationStatusName({ commit }, {name, id = null}) {
      const params = {
        name,
        id
      }
      const results = await requestHandler({
        title: 'checkOrderIntegrationStatusName',
        methodName: 'checkOrderIntegrationStatusName',
        method: () => {
          return HTTP.get('OrderIntegrationStatus/check/name', { params })
        },
        success: result => {
          return result
        }
      })
      return results
    }
  },
  mutations: {
    SET_LIST(state, newList) {
      state.orderIntegrationStatusList = newList.orderIntegrationStatusList || []
      state.Page.number = newList.pageNumber
      state.Page.size = newList.pageSize
      state.Page.total = newList.totalCount
    },

    SET_USED_CLOSURE_STATUS_ITEM(state, list) {
      state.isHasClosure = !!list.find(item => item.isClosureStatus)
    }
  }
}
