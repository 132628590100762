<template lang="pug">
DeleteAlert(
    :item="item",
    :fields="fields",
    :tableName="$t('OrderIntegrationStatus.remove_TableName')",
    loading="onDeleteOrderIntegration",
    @delegateOnAlertRemoveOk="itemRemove"
  )
</template>

<script>
import { mapActions } from 'vuex'
import { vueWaitLoader } from '@/utils/baseOperations'

export default {
  name: 'OrderIntegrationStatusRemove',

  data () {
    return {
      fields: [{
        name: 'name',
        is_visible: true,
        short_title: this.$t('OrderIntegrationStatus.remove_TableColumn_Name_Short'),
        title: this.$t('OrderIntegrationStatus.remove_TableColumn_Name')
      }],
      item: {}
    }
  },

  mounted () {
    this.item = this.$route.params.item
    if (!this.item) {
      this.getOrderIntegrationStatus({id: this.$route.params.id})
        .then(res => {
          if (res) this.item = res.data.orderIntegrationStatus
        })
    }
  },

  methods: {
    ...mapActions('Notify', [
      'notifyShow'
    ]),

    ...mapActions('OrderIntegrationStatus', [
      'deleteOrderIntegrationStatus',
      'getOrderIntegrationStatus'
    ]),

    close () {
      this.$router.push({
        name: this.$route.matched[0].name
      })
    },

    itemRemove: vueWaitLoader(async function () {
      await this.deleteOrderIntegrationStatus({id: this.item.id})
        .then(res => {
          if (res) {
            const message = this.$t('Global.notification_Deleted', { name: this.item.name })
            this.notifyShow({ message })
            this.$emit('getList')
            this.close()
          }
        })
    }, 'onDeleteOrderIntegration')
  }
}
</script>

<style lang="scss">
</style>
